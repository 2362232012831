<template>
    <common-form
            ref="form"
            url="web/iot/water/camera"
            title="添加摄像头"
            :beforeRequest="beforeRequest"
            :items="items"
            @success="$emit('success')"></common-form>
</template>
<script>

    export default {
        props:["id"],
        data() {
            return {
                edit: false,
            }
        },
        computed: {
            items() {
                return [
                    {
                        type: "input",
                        name: "名称",
                        key: "name",
                        required: true
                    },
                    {
                        type: "input",
                        name: "9位设备序列号",
                        key: "cameraSn",
                        required: true
                    },
                    {
                        type: "input",
                        name: "设备验证码",
                        key: "cameraCode",
                        required: true
                    },

                ]
            }
        },
        methods: {
            show() {
                this.$refs.form.show({})
            },
            beforeRequest(model){
                return {
                    ...model,
                    deviceId:this.id
                }
            }

        }

    }
</script>
